export default {
  data () {
    return {
      checkboxOpen: false,
      items: [],
    }
  },
  methods: {
    // for BindWith mixin
    postRefreshBind(bind){
      this.items = bind ? bind.[this.keyname].split(';') : []
      this.checkCurrentNicsNum()
    },

    // for expansion-panels
    selected(item){
      this.model = item
      this.checkCurrentNicsNum()
    },

    checkCurrentNicsNum(){
      if (this.model){
        if (this.model.split(';').length > 1){
          this.currentLock = false
          this.checkboxOpen = false
        } else {
          this.checkboxOpen = true
        }
      }
    }
  }
}