<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Status: {{currentStatusStr}}
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <v-row 
        align="center"
      >
        <v-spacer/>
        <v-btn 
          x-small
          class="grey--text"
          to="/docs/users-manual/binds/status"
        >
          guide
        </v-btn>
      </v-row>
      <v-row 
        align="center"
      >
        <v-select
          v-model="selectedStatus"
          :items="nextStatuses"
          item-text="text"
          item-value="value"
          label="Change Status to"

        ></v-select>
      </v-row>

      <v-divider/>

      <p>type: {{type}}</p>

      <v-divider/>

      <p>Expiration: {{toDateString(Expiration*1000)}}</p>
      <v-btn v-if="Expiration" @click="purchase">1 year extension</v-btn>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
/* eslint no-unused-vars: 0 */
import * as firebase from 'firebase'  // cdn
//import firebase from "firebase/app" // npm
import "firebase/auth";
import axios from 'axios'
// @ is an alias to /src
import {loadStripe} from '@stripe/stripe-js'

var sprintf = require('sprintf-js').sprintf,
    vsprintf = require('sprintf-js').vsprintf

// import moment from 'moment/moment'
import dayjs from 'dayjs';
//import DateString from '@/components/DateString'
import DateString from 'vue-faui-user-fe3/src/components/DateString'
export default {
  mixins: [DateString],
  props: ['bind'],
  data () {
    return {
      dialog: false,
      Expiration: this.bind ? this.bind.Expiration : 0,
      Activated:  this.bind ? this.bind.Activated  : 0,
      nextStatuses: [
        {text: "Not active", value: 2},
        {text: "Bind Waiting", value: 3},
        {text: "Auto Bind Waiting", value: 4},
//        {text: "Bind Requesting", value: 5},
        {text: "Binding", value: 6},
      ],
//      selectedStatus: this.bind ? this.bind.Status : 0,
      selectedStatus: 0
    }
  },
  methods: {
    refreshBind(bind){
      console.log("Status - refreshBind", bind)
      this.selectedStatus = 0
      this.Expiration = this.bind.Expiration
      this.Activated  = this.bind.Activated
//      this.selectedStatus = bind.Status
      console.log("this.selectedStatus",this.selectedStatus)
    },
    updateBind(updates, bind){
      if (this.selectedStatus != 0 && this.selectedStatus != bind.Status){
        // binds
        bind.Status = this.selectedStatus
        // updates
        updates["status"] = this.selectedStatus
      }
      if (this.Expiration != bind.Expiration){
        bind.Expiration = this.Expiration
        updates["expiration"] = this.Expiration
        updates["activated"]  = this.Activated
      }
    },
    /*
    initiallySelected(status){
      switch (status){
        case 2:
          return { label: "Not active", value: 2}
        case 3:
          return { label: "Bind Waiting", value: 3}
        case 4:
          return { label: "Auto Bind Waiting", value: 4}
        case 5:
          return { label: "Bind Requesting", value: 5}
        case 6:
          return { label: "Binding", value: 6}
      }
    },
    */
    bindStatus(status){
      switch (status){
        case 2:
          return "Not Active"
        case 3:
          return "Bind Waiting"
        case 4:
          return "Auto Bind Waiting"
        case 5:
          return "Bind Requesting"
        case 6:
          return "Binding"
        case 7:
          return "Expired"
        case 8:
          return "Invalidated"
      }
    },
    setExpiration(){
      if (this.type == "With Expiration"){
        const now = dayjs() //moment()
        this.Activated  = now.unix() 
        const oneyearlater = now.add(1,'years')
        this.Expiration = oneyearlater.unix() 
      }
    },
    clearExpiration(){
      this.Expiration = 0
      this.Activated  = 0
    },
    purchase(){
      var user = firebase.auth().currentUser;
      if (user) {
        user.getIdToken(/* forceRefresh */ true).then((idToken) => {
          const url = sprintf(this.$koshinto + '/v1/sessionIDforExtention/%s', idToken)
          axios
          .post(url,{PriceID: this.$stripeEnv =="live" ? "price_1HlZDiG8LX1Yc059w4WBggei" : "price_1HaIb6G8LX1Yc059ggxwJ0Kr", Unit: 1, BindID: this.bind.Bid})
          .then(response => {
            if (response.status == 200) {
              console.log("response = ", response)
              this.stripe.redirectToCheckout({sessionId: response.data.SessionID})
            }
          });
        });
      }
    },
  },
  async mounted() {
    this.stripe = await loadStripe(this.$stripe)
  },
  computed: {
    type(){
      switch (this.bind.Type){
        case 1:
          return "Expiration Free"
        case 2:
          return "Expiration Free"
        case 4:
          return "With Expiration"
      }
      return ""
    },
    currentStatusStr(){
      return this.bindStatus(this.bind.Status)
    }
  },
  watch: {
    selectedStatus (newSelectedStatus, oldSelectedStatus) {
      console.log("new:",newSelectedStatus,"old",oldSelectedStatus)
      if ((newSelectedStatus == 3 || newSelectedStatus == 4 || newSelectedStatus == 6) && this.bind.Expiration == 0)
      {
        this.setExpiration()
      } else if (newSelectedStatus == 2 && this.bind.Expiration == 0){
        this.clearExpiration()
      } 
    }
  }
}
</script>